<template>
  <svg
                style="margin-bottom: 1px; margin-left: 5px;"
                width="9px"
                height="9px"
                viewBox="0 0 9 9"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                v-b-tooltip.hover.v-dark.html="content"
              >
                <g
                  id="LATEST"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                    id="info"
                    fill="#00A1D2"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </svg>
</template>

<script>
export default {
    name:'customtooltip',
props:["content"],
data(){
    return {
    TooltipInfoIcon:require("@/assets/Images/checkbox_selected.svg")
    }
}
}
</script>

<style>
.popover.b-popover .arrow::after {
    border-top-color:transparent !important;
}
.popover.b-popover .popover-body > * {
color:#ffffff ;
font-family:'Open Sans';
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    border-right-color: #212529 !important;
    opacity:0.9;
}
.btn-tooltip.btn {
    background-color: transparent !important;
    border: none !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

}
.btn-secondary.btn-tooltip:focus {
box-shadow: none !important;
-webkit-box-shadow:none !important;
}
.popover.b-popover {
    width: 400px !important;
    height: auto;
    padding: 5px;
    background-color:#2c3865;
    color:#ffffff;
    font-family: "Open Sans";
    font-size: 14px !important;
    font-weight: normal !important;
}
.popover-header{
    background-color: #2c3865!important;
    color:#ffffff !important;
    font-family: "Open Sans" !important;
    font-size: 13px !important;
    font-weight: normal !important;
} 
</style>